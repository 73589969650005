import { createSelector } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"

import type { IRootState } from "@/store"

const selectModals = (state: IRootState) => state.modal.modals

const selector = createSelector(selectModals, modals => modals) // TODO: check if needed

function useModalSelector() {
    return useSelector(selector)
}

export { useModalSelector }
